import { Injectable } from '@angular/core';
import { getIdToken } from '@angular/fire/auth';
import { Subscription, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ElasticService {
  constructor(private authService: AuthService, private http: HttpClient) {}

  currentSubscription?: Subscription;

  query = (
    index: string | string[],
    query: any = {},
    prefix = environment.elasticPrefix,
    cancelLast = false
  ): Promise<any> =>
    new Promise((resolve, reject) => {
      if (cancelLast && this.currentSubscription) {
        this.currentSubscription.unsubscribe();
      }

      this.authService.user$.pipe(take(1)).subscribe(user => {
        if (user) {
          getIdToken(user?.user).then(token => {
            if (typeof index === 'string') index = [index];

            const indices = index.map(i => prefix + i).join(',');
            this.currentSubscription = this.http
              .post(environment.elasticHttp + indices + '/_search', query, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .subscribe({
                next: res => {
                  resolve(res);
                },
                error: err => {
                  reject(err);
                },
              });
          });
        }
      });
    });
}
